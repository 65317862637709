<template>
<div class="item-image">
  <div class="form-wrap fileupCol">
    <p class="form-wrap-tit">{{$t('DETAIL_BID_ITEM_IMAGES')}}</p>
    <div class="files-flex">
      <div
        v-for="(img, index) in initItemImages"
        :key="img.id"
       class="files-flex-list"
      >
        <p class="tit">{{ img.label }}</p>
        <div class="files-flex-list-box">
          <v-img
            :src="getImageUrl(img.id)"
            :aspect-ratio="4/3"
            cover
            class="grey lighten-2"
            @click="onImgClick($event, img.id, 1)"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>
      </div>
    </div>
  </div>
 <div v-if="optionalImages && optionalImages.length > 0" class="form-wrap fileupAny">
    <p class="form-wrap-tit">{{$t('DETAIL_BID_ITEM_OPTIONAL_IMAGES')}}</p>
    <div class="files-flex">
      <div
        v-for="(img, index) in optionalImages"
        :key="img.id"
       class="files-flex-list"
      >
        <p class="tit">{{ img.file_title || '' }}</p>
        <div class="files-flex-list-box">
          <v-img
            :src="$options.filters.getFullImagePath(img.file_path) || require('@/assets/img/no_photo.jpg')"
            :aspect-ratio="4/3"
            cover
            class="grey lighten-2"
            @click="onImgClick($event, img.physical_name, 2)"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>
      </div>
    </div>
  </div>

  <PhotoSwipe ref="photoswipe"/>
</div>
</template>
<script>
import CommonFilters from '@/common/commonFilters'
import PhotoSwipe from '@/components/Gallery/PhotoSwipe.vue'
export default {
  components : {
    PhotoSwipe
  },
  mixins : [CommonFilters],
  props  : {
    itemImages : {
      type    : Array,
      default : () => []
    },
    optionalImages : {
      type    : Array,
      default : () => []
    },
    constants : {
      type    : Object,
      default : () => {}
    },
  },
  data() {
    return {
      initItemImages : [
        {id : 1, title : '', fileName : null, key : null, preview : null, label : '左'},
        {id : 2, title : '', fileName : null, key : null, preview : null, label : '右'},
        {id : 3, title : '', fileName : null, key : null, preview : null, label : '前'},
        {id : 4, title : '', fileName : null, key : null, preview : null, label : '後'},
        {id : 5, title : '', fileName : null, key : null, preview : null, label : 'メーター'},
        {id : 6, title : '', fileName : null, key : null, preview : null, label : 'タンク中'},
        {id : 7, title : '', fileName : null, key : null, preview : null, label : 'エンジン左'},
        {id : 8, title : '', fileName : null, key : null, preview : null, label : 'エンジン右'},
        {id : 9, title : '', fileName : null, key : null, preview : null, label : 'エンジン前'},
        {id : 10, title : '', fileName : null, key : null, preview : null, label : 'エンジン番号'},
        {id : 11, title : '', fileName : null, key : null, preview : null, label : 'シート'},
        {id : 12, title : '', fileName : null, key : null, preview : null, label : '前方ホイール左'},
        {id : 13, title : '', fileName : null, key : null, preview : null, label : '前方ホイール右'},
        {id : 14, title : '', fileName : null, key : null, preview : null, label : '前方タイヤ'},
        {id : 16, title : '', fileName : null, key : null, preview : null, label : '後方ホイール左'},
        {id : 17, title : '', fileName : null, key : null, preview : null, label : '後方ホイール右'},
        {id : 15, title : '', fileName : null, key : null, preview : null, label : '後方タイヤ'},
        {id : 19, title : '', fileName : null, key : null, preview : null, label : 'リアサスペンション左'},
        {id : 18, title : '', fileName : null, key : null, preview : null, label : 'リアサスペンション右'},
      ],
      initOptionalImages : [
        {id : 1, title : '', fileName : null, key : null, preview : null},
        {id : 2, title : '', fileName : null, key : null, preview : null},
        {id : 3, title : '', fileName : null, key : null, preview : null},
        {id : 4, title : '', fileName : null, key : null, preview : null},
        {id : 5, title : '', fileName : null, key : null, preview : null},
        {id : 6, title : '', fileName : null, key : null, preview : null},
        {id : 7, title : '', fileName : null, key : null, preview : null},
        {id : 8, title : '', fileName : null, key : null, preview : null},
        {id : 9, title : '', fileName : null, key : null, preview : null},
        {id : 10, title : '', fileName : null, key : null, preview : null}
      ]
    }
  },
  methods : {
    getImageUrl(imgId) {
      const file_path = (this.itemImages.find(x => String(x.physical_name) === String(imgId)) || {}).file_path
      return file_path ? this.$options.filters.getFullImagePath(file_path) : require('@/assets/img/no_photo.jpg')
    },
    onImgClick(event, imgId, type) {
      let idx = -1
      if (type === 1) {
        idx = this.itemImages.findIndex(x => String(x.physical_name) === String(imgId))
      } else {
        idx = this.optionalImages.findIndex(x => String(x.physical_name) === String(imgId))
        idx += this.itemImages.length
      }
      if (idx >= 0) {
        this.$refs.photoswipe.onSlideClick(event, idx, this.allImages)
      }
    }
  },
  computed : {
    allImages() {
      return (this.itemImages || []).concat(this.optionalImages || [])
    }
  }
}
</script>
<style scoped lang="scss">
  .item-image {
    margin-top: 0;
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
