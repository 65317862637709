<template>
<main id="main" class="stock">
  <div id="pNav" class="bgLGray">
    <ul>
      <li><a @click="$routerGo($define.PAGE_NAMES.TOP_PAGE_NAME)">TOP</a></li>
      <li><a @click="$routerGo(parent.searchPageRouterName)">{{$t(parent.searchPageNameTag)}}</a></li>
      <li>{{$t(parent.nameTag)}}</li>
    </ul>
  </div>

  <div id="terms" class="wrap1160 com-item-box">
    <p class="tit-terms">{{$t('ITEM_SEARCH_CONDITIONS_LABEL')}}</p>
    <div class="tag-terms">
      <a v-for="(row, index) in getFiltered" :key="index">{{row}}</a>
    </div>
    <p v-if="$route.name!=$define.PAGE_NAMES.STOCK_DETAIL_PAGE_NAME" class="tit-period">{{$t("DETAIL_ITEM_HOLDING_PERIOD_LABEL")}}</p>
    <p v-if="$route.name!=$define.PAGE_NAMES.STOCK_DETAIL_PAGE_NAME" class="txt-period"><span>{{item.start_datetime | moment($t('DATE_TIME_FORMAT'))}}</span><span>～</span><span>{{item.end_datetime | moment($t('DATE_TIME_FORMAT'))}}</span></p>
    <div v-if="item.exhibition_division === 2" id="sns-share">
      <p>{{$t('DETAIL_ITEM_SNS_SHARE_LABEL')}}</p>
      <transition name="fade" mode="out-in">
        <ShareThis v-if="isShareThisVisible" :url="getShareUrl()" :imageUrl="getShareImageUrl()"/>
      </transition>
    </div>
    <span v-if="item.attention_info" v-bind:class="{'fav-mark': true, 'active': item.attention_info.is_favorited}" @click="favoriteItem(item.attention_info.is_favorited)"></span>
  </div>

  <div class="wrap1160">
    <section id="machine-data">
      <div class="mech_d-main">
        <Gallery
          :key="item.exhibition_item_no"
          :images="item.images"
          :exhibitionItemNo="String(item.exhibition_item_no)"
        />

        <div class="mech_d-main-txt">
          <p class="stock-location" v-if="$route.name !== $define.PAGE_NAMES.STOCK_LIST_PAGE_NAME && (!item.exhibition_member_no && item.exhibition_division === 2)">
            {{ $t('ITEM_ROW_EXHIBIT_UEJ_LABEL') }}
          </p>
          <p class="stock-location" v-if="$route.name !== $define.PAGE_NAMES.STOCK_LIST_PAGE_NAME && (item.exhibition_member_no && item.exhibition_division === 2)">
            {{ $t('ITEM_ROW_EXHIBIT_UEJ_LABEL') }}
          </p>
          <p class="stock-location" v-if="$route.name !== $define.PAGE_NAMES.STOCK_LIST_PAGE_NAME && (item.exhibition_division === 1)">
            {{ $t('ITEM_ROW_EXHIBIT_MEMBER_LABEL') }}
          </p>
          <p v-if="item.free_field.makerName" class="price-system">{{ item.free_field.makerName }}</p>
          <!-- <p class="price-system">{{$t(parent.typeTag)}}</p> -->
          <!-- <p v-if="item.is_recommending" class="recommending-status">{{$t("ITEM_SEARCH_RECOMMEND_LABEL")}}</p> -->
          <p v-if="free_fields && free_fields.length > 1" style="margin-top: 5px;color: red;font-size: 15px;">{{$t("DETAIL_ITEM_LOT_ITEMS_PS_TEXT")}}</p>
          <div class="mech_d-main-data">
            <dl>
              <dt>{{$t("ITEM_ROW_BIKE_TYPE_LABEL")}}</dt>
              <dd>{{item.free_field.bike_type}}</dd>
              <dt>{{$t("ITEM_ROW_CUBIC_CAPACITY")}}</dt>
              <dd>{{item.free_field.cubic_capacity_other | getCubicText($t('ITEM_FREE_FIELD_FUMEI'))}}</dd>
              <dt>{{$t("ITEM_ROW_MILEAGE")}}</dt>
              <dd>{{$options.filters.number2string(item.free_field.mileage_other, null, 'km') || $t('ITEM_FREE_FIELD_FUMEI')}}</dd>
              <dt>{{$t("ITEM_ROW_INSPECTION_DATE")}}</dt>
              <dd>{{getInspectionTxt(item.free_field.inspec_expire, item.free_field.inspec_expire_other)}}</dd>
            </dl>
          </div>
          <div class="mech_d-main-price" v-if="$route.name == $define.PAGE_NAMES.STOCK_DETAIL_PAGE_NAME || !item.bid_status">
            <dl v-if="item.free_field">
              <dt><span class="current-p">{{$t("ITEM_SEARCH_PRICE_LABEL")}}</span></dt>
              <dd v-if="item.attention_info && item.price_display_flag == 0"><span class="current-p">{{item.sold_out ? $t("ITEM_ROW_SOLD_OUT_LABEL") : 'ASK'}}</span></dd>
            </dl>
            <div class="mech_d-main-system" v-if="item.attention_info">
              <div class="btn-box">
                <a class="btn-contact" @click="$routerGo(parent.contactPageName, $route.params.id)"><img src="@/assets/img/common/ic_mail_white.svg" alt="問い合わせ">問い合わせ</a>
              </div>
              <ul class="action" v-if="item.attention_info">
                <li v-if="item.attention_info.view_count" class="action-view"><img src="@/assets/img/common/ic_view.svg" alt=""><span>{{item.attention_info.view_count}}</span></li>
                <li v-if="item.attention_info.favorited_count" class="action-fav"><img src="@/assets/img/common/ic_stars.svg" alt=""><span>{{item.attention_info.favorited_count}}</span></li>
                <li v-if="item.attention_info.bid_count" class="action-bid"><img src="@/assets/img/common/ic_check.svg" alt=""><span>{{item.attention_info.bid_count}}</span></li>
              </ul>
            </div>
            <div class="mech_d-main-system" v-else>
              <div class="btn-box">
                <a class="btn-login" @click="$routerGo($define.PAGE_NAMES.LOGIN_PAGE_NAME, null, {next: $route.name, id: $route.params.id, filter: $route.query.filter})">{{$t("ITEM_ROW_LOGIN_LINK_LABEL")}}<img src="@/assets/img/common/ic_arrow_white.svg" alt=""></a>
                <a class="btn-signup" @click="$routerGo($define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME)">{{$t('ITEM_ROW_NEW_MEMBER_REGISTRATION_BUTTON_LABEL')}}<img src="@/assets/img/common/ic_arrow_white.svg" alt=""></a>
              </div>
            </div>
          </div>
          <div class="mech_d-main-price" v-else>
            <dl>
              <dt v-if="$route.name == $define.PAGE_NAMES.AUCTION_DETAIL_PAGE_NAME">{{$t("DETAIL_LOWEST_BID_ACCEPT_PRICE_LABEL")}}</dt>
              <dd v-if="$route.name == $define.PAGE_NAMES.AUCTION_DETAIL_PAGE_NAME">{{$t("ITEM_ROW_PRICE_CURRENCY_MARK")}}{{item.bid_status.lowest_bid_price.numberString()}}</dd>
              <dt v-if="$route.name == $define.PAGE_NAMES.AUCTION_DETAIL_PAGE_NAME && item.attention_info.bid_count > 0"><span class="current-p">{{$t("ITEM_ROW_CURRENT_PRICE_LABEL")}}</span></dt>
              <dt v-else-if="$route.name !== $define.PAGE_NAMES.AUCTION_DETAIL_PAGE_NAME"><span class="current-p">{{$t("DETAIL_LOWEST_BID_ACCEPT_PRICE_LABEL")}}</span></dt>
              <dd v-if="$route.name !== $define.PAGE_NAMES.AUCTION_DETAIL_PAGE_NAME || item.attention_info.bid_count > 0"><span class="current-p">{{item.sold_out ? $t("ITEM_ROW_SOLD_OUT_LABEL") : ($t("ITEM_ROW_PRICE_CURRENCY_MARK") + item.bid_status.current_price.numberString())}}</span></dd>
              <dt v-if="$route.name == $define.PAGE_NAMES.AUCTION_DETAIL_PAGE_NAME" class="mt-0"></dt>
              <dd v-if="$route.name == $define.PAGE_NAMES.AUCTION_DETAIL_PAGE_NAME" class="mt-0">
                <div v-if="!item.sold_out" class="bidPrice mb-2">
                  <span class="bidP-tax">
                    （{{$t('ITEM_ROW_PRICE_WITH_TAX_LABEL')}}: {{$t("ITEM_ROW_PRICE_CURRENCY_MARK")}}{{taxPrice(item.bid_status.current_price)}}）
                  </span>
                </div>
              </dd>

              <dt v-html="$t('DETAIL_ITEM_SHIPPING_FEE_LABEL')"></dt>
              <dd v-if="shipping_amount">{{$t("ITEM_ROW_PRICE_CURRENCY_MARK")}}{{shipping_amount | number2string}}</dd>
              <dd v-else-if="has_shipping_address === null || has_shipping_address">
                {{ $t("DETAIL_ITEM_SHIPPING_FEE_NOT_FOUND") }}
                <a class="link-text" @click="$routerGoNewWindow($define.PAGE_NAMES.OTHER_GUIDE_DELIVERY_PAGE_NAME)">{{$t("DETAIL_ITEM_SHIPPING_LINK_TXT")}}</a>
              </dd>
              <dd v-else style="color: #808080;">{{ $t("DETAIL_ITEM_SHIPPING_ADD_NOT_SET") }}</dd>

              <dt>{{$t("DETAIL_BID_STATUS_LABEL")}}</dt>
              <dd v-if="item.bid_status.status === 0">{{$t("DETAIL_BID_STATUS_UNBID_LABEL")}}</dd>
              <dd v-if="item.bid_status.status === 1">{{$t("DETAIL_BID_STATUS_BIDED_LABEL")}}</dd>
              <dd v-if="item.bid_status.status === 3">{{$t("DETAIL_BID_STATUS_SUCCESSFUL_BID_RIGHT_LABEL")}}</dd>
            </dl>

            <div class="bid-history" v-if="bid_histories && bid_histories.length > 0">
              <p class="bid-history-label">{{$t('DETAIL_BID_CURRENT_CANDIDATE_LABEL')}}</p>
              <p>
                <span>{{ bid_histories[0].create_datetime | dateTimeDayFormat }}</span>
                {{ '　' }}
                <span>{{ bid_histories[0].nickname }}</span>
              </p>
            </div>

            <div class="status">
              <p class="msg-red" v-if="item.bid_status.is_top_member">{{$t("ITEM_ROW_TOP_STATUS_LABEL")}}</p>
              <p class="msg-second" v-if="item.bid_status && item.bid_status.is_second_member">{{$t("ITEM_ROW_SECOND_STATUS_LABEL")}}</p>
              <p class="msg-normal" v-if="item.bid_status.is_exceeding_lowest_price">{{$t("ITEM_ROW_EXCEEDING_THE_LOWEST_BID_STATUS_LABEL")}}</p>
              <p class="msg-normal" v-if="item.bid_status.is_not_exceeding_lowest_price">{{$t("ITEM_ROW_NOT_EXCEEDING_THE_LOWEST_BID_STATUS_LABEL")}}</p>
              <p class="msg-normal" v-if="item.bid_status.is_cancel">{{$t("ITEM_ROW_CANCEL_LABEL")}}</p>
              <p class="msg-normal" v-if="item.bid_status.is_more_little">{{$t("ITEM_ROW_MORE_LITTLE_LABEL")}}</p>
              <a @click="reloadItemStatus" :class="{'btn-reload': true, 'fix-width': item.bid_status.is_exceeding_lowest_price && !item.bid_status.is_top_member}"><img src="@/assets/img/common/ic_reload.svg" alt=""></a>
            </div>
            <div class="mech_d-main-system">
              <div class="bidForm">
                <v-tooltip top
                  :color="((errors.length > 0 && errors[0].success) ? 'success' : 'error')"
                  v-model="errors.length">
                  <template v-slot:activator="{ on, attrs }">
                    <div>
                      <form v-on:submit.prevent="" v-if="item.bid_status.can_bid">
                        <div class="bidPrice">
                          <p class="bidP-tit"><span v-if="item.bid_status.automatic_bidding">{{$t("DETAIL_BID_AUTOMATIC_BIDDING_LABEL")}}</span>{{$t("ITEM_ROW_BID_PRICE_LABEL")}}</p>
                          <div class="bidP-txt">
                            <span class="yen">¥</span>
                            <input
                              @focus="errors=[]"
                              v-bind:class="{'ipt-price': true, 'err': (errors.length > 0 && !errors[0].success)}"
                              v-model="inputBidPrice"
                              type="text"
                              maxlength="5"
                              v-on:keypress="isNumber($event)"
                              v-on:focusin="inputBidPrice = onFocusin_start_price($event)"
                              v-on:focusout="inputBidPrice = onFocusout_start_price($event)"
                             >
                           {{item.bid_status.pitch_width.numberString().substring(1)}}
                          </div>
                        </div>
                        <div class="bidP-btn">
                          <button v-if="item.bid_status.pitch_button_1" @click="addPitch(item.bid_status.pitch_button_1)">¥{{item.bid_status.pitch_button_1.numberString()}}</button>
                          <button v-if="item.bid_status.pitch_button_2" @click="addPitch(item.bid_status.pitch_button_2)">¥{{item.bid_status.pitch_button_2.numberString()}}</button>
                          <button v-if="item.bid_status.pitch_button_3" @click="addPitch(item.bid_status.pitch_button_3)">¥{{item.bid_status.pitch_button_3.numberString()}}</button>
                        </div>
                        <div class="bidP-enter">
                          <button @click="bidItemClick(parent.typeTag)">{{$t("ITEM_ROW_BID_BUTTON_LABEL")}}</button>
                        </div>
                      </form>
                      <p class="bidP-fin" v-if="item.bid_status.extending">
                        <ExtendCountDown
                         :remainingSeconds="item.bid_status.remaining_seconds"
                         :endDatetime="item.bid_status.end_datetime"
                         :key="item.bid_status.remaining_seconds"/>
                       </p>
                      <p class="bidP-fin" v-else-if="item.bid_status.started">{{item.bid_status.remaining_seconds > 0 ? $t("ITEM_ROW_END_STATUS_LABEL") : $t("ITEM_ROW_ENDED_STATUS_LABEL")}} : {{item.bid_status.end_datetime | dateTimeFormat}}</p>
                      <p class="bidP-fin" v-else>{{$t("ITEM_ROW_START_STATUS_LABEL")}} {{item.bid_status.start_datetime | dateTimeFormat}}</p>
                    </div>
                  </template>
                  <div :class="errors.length>0 && errors[0].success ? 'custom-tooltip': ''">
                    <div style="padding: 10px;" v-if="errors.length>0">
                      <div v-for="(message,index) in errors" :key="index">{{message.errorMessage}}</div>
                    </div>
                  </div>
                </v-tooltip>
              </div>
              <ul class="action" v-if="item.attention_info">
                <li v-if="item.attention_info.view_count" class="action-view"><img src="@/assets/img/common/ic_view.svg" alt=""><span>{{item.attention_info.view_count}}</span></li>
                <li v-if="item.attention_info.favorited_count" class="action-fav"><img src="@/assets/img/common/ic_stars.svg" alt=""><span>{{item.attention_info.favorited_count}}</span></li>
                <li v-if="item.attention_info.bid_count" class="action-bid"><img src="@/assets/img/common/ic_check.svg" alt=""><span>{{item.attention_info.bid_count}}</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="mech_d-btn">
        <p class="pic"></p>
        <!-- <a class="pic" @click="downloadItemImages" target="_blank"><img src="@/assets/img/common/ic_dl_bgreen.svg" alt="">{{$t("DETAIL_DOWNLOAD_IMAGES_BUTTON_LABEL")}}</a> -->
        <a
          v-if="item.login_member_no === null || (item.bid_status && !item.bid_status.is_cancel)"
          class="ctc"
          @click="$routerGo($define.PAGE_NAMES.CHAT_INQUIRY_PAGE_NAME, item.exhibition_item_no)"
         >
         <img src="@/assets/img/common/ic_mail_bgreen.svg" alt="">{{$t("DETAIL_CONTACT_PRODUCT_BUTTON_LABEL")}}
        </a>
        <a class="ins" v-if="item.pdfs && item.pdfs.length > 0" :href="'/' + encodeURIComponent(item.pdfs[0])" target="_blank"><img src="@/assets/img/common/ic_file_bgreen.svg" alt="">{{$t("DETAIL_DOWNLOAD_INSPECTION_REPORT_BUTTON_LABEL")}}</a>
      </div>

      <div class="mech_d-spec">
        <h3>{{$t("DETAIL_PRODUCT_INFORMATION_LABEL")}}</h3>
        <div
          v-for="(free_field, index) in (free_fields || [item.free_field])"
          class="exhibit"
          :class="(free_fields && free_fields.length > 1 && index !== (free_fields.length - 1)) ? 'mech_d-spec-list multi-free-field' : 'mech_d-spec-list'"
          :key="index"
        >
          <DetailPreview
            :free-field="free_field"
            :item-images="itemImages"
            :optional-images="optionalImages"
            :constants="itemSearchStore.constants"
            :getInspectionTxt="itemSearchStore.getInspectionTxt"
          />
        </div>
        <div class="mech_d-spec-note">
          <div class="mech_d-note-txt">{{item.free_field.REMARKS}}</div>
          <!-- <a class="btn-ctc" @click="$routerGo(parent.contactPageName, $route.params.id)"><img src="@/assets/img/common/ic_mail_white.svg" alt="">{{$t("DETAIL_CONTACT_PRODUCT_BOTTOM_BUTTON_LABEL")}}</a> -->
        </div>
      </div>

      <a class="btn-back-search" @click="$routerGo(parent.searchPageRouterName, null, {filter:$route.query.filter})">{{$t("DETAIL_BACK_TO_ITEM_SEARCH_BUTTON_LABEL")}}<img src="@/assets/img/common/ic_arrow_white.svg" alt=""></a>
    </section>
  </div>

  <div id="npNav">
    <a v-if="itemIndex > 0" @click="prevBtnClicked" class="prev">{{$t("DETAIL_ITEM_PREV_BUTTON_LABEL")}}</a>
    <a v-if="items.length > itemIndex + 1 || isMoreLimit" @click="nextBtnClicked" class="next">{{$t("DETAIL_ITEM_NEXT_BUTTON_LABEL")}}</a>
  </div>

  <BidConfirmDialog
    :parent="$data"
    :grandParent="parent"
    :confirmBidDialog="confirmBidDialog"
    :bidConfirmItems="bidConfirmItems"
    :bidConfirm="bidConfirm"
    :bidConfirmMessages="bidConfirmMessages"
    :closeBidConfirmDialog="closeBidConfirmDialog"
    :bidConfirmDialogMode="bidConfirmDialogMode"
    :bidConfirmSuccess="bidConfirmSuccess"
    :errors="bidConfirmMessages"
    :bidConfirmSuccessMessage="bidConfirmSuccessMessage"
    :favorite="favoriteItem"
    :getPriceIncludeTax="itemSearchStore.getPriceIncludeTax"
    :getInspectionTxt="itemSearchStore.getInspectionTxt"
  />
</main>
</template>

<script>
import Methods from '@/api/methods'
import CommonFilters from '@/common/commonFilters'
import Gallery from '@/components/Gallery/Gallery'
import useItemSearch from '@/stores/useItemSearch'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'
import moment from 'moment'
import BidConfirm from './mixins/BidConfirm.js'
import DetailBid from './mixins/DetailBid'
import WebSocket from './mixins/WebSocket.js'
import BidConfirmDialog from './parts/BidConfirmDialog'
import DetailPreview from './parts/DetailPreview.vue'
import ExtendCountDown from './parts/ExtendCountDown'

import ShareThis from './parts/ShareThis'

export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const itemSearchStore = useItemSearch()
    return {
      loadingStore,
      itemSearchStore
    }
  },
  components : {
    ExtendCountDown,
    ShareThis,
    BidConfirmDialog,
    Gallery,
    DetailPreview,
  },
  props : {
    nameTag : {
      type    : String,
      default : null
    },
    parent : {
      type    : Object,
      default : Object
    },
    search : {
      type    : Function,
      default : Object
    },
    downloadImages : {
      type    : Function,
      default : Object
    },
    favorite : {
      type    : Function,
      default : Object
    },
    refreshItem : {
      type    : Function,
      default : Object
    }
  },
  mixins : [DetailBid, WebSocket, BidConfirm, CommonFilters],
  data() {
    return {
      scripts : [
        'prev-next.js',
        'gallery/ofi.js'
      ],
      item : {
        free_field     : {},
        images         : [],
        itemImages     : [],
        optionalImages : [],
      },
      free_fields          : [],
      bid_histories        : [],
      shipping_amount      : null,
      has_shipping_address : null,
      itemIndex            : null,

      isShareThisVisible : true,
      isSliderVisible    : false
    }
  },
  computed : {
    items() {
      return this.itemSearchStore.items
    },
    isMoreLimit() {
      return this.itemSearchStore.isMoreLimit
    },
    getFiltered() {
      return this.itemSearchStore.getFiltered(this.itemSearchStore.filteredObject, this.parent.typeTag)
    },
    filter() {
      return this.itemSearchStore.filter
    },
    itemImages() {
      return this.item.itemImages
    },
    optionalImages() {
      return this.item.optionalImages
    }
  },
  mounted() {
    // Methods.addExternalScripts(this.scripts)
  },
  created() {
    try {
      if (this.$route.query && this.$route.query.filter) {
        this.itemSearchStore.updateFilter(JSON.parse(decodeURIComponent(this.$route.query.filter)))
      } else if (this.$route.params.id) {
        this.filter.exhibitionItemNos = [this.$route.params.id]
      }
      if (!this.$route.params.id) {
        delete this.filter.exhibitionItemNos
        delete this.filter.recommending
        delete this.filter.newStock
      }
      if (this.extraFilter) {
        this.itemSearchStore.updateFilter(Object.assign({}, this.filter, this.extraFilter))
      }
    } catch (e) {
      console.log(e)
    }
    if (this.$route.meta.enableQuery) {
      this.itemSearchStore.getItemSearchConstants(this.$router)
        .then(() => {
          this.getCurrentItem()
        })
    } else {
      this.itemSearchStore.searchItems(this.$router)
        .then(() => {
          this.getCurrentItem()
        })
    }
  },
  methods : {
    getCurrentItem() {
      this.itemIndex = this.items.findIndex(item => String(item.exhibition_item_no) === String(this.$route.params.id))
      if (this.itemIndex === -1) {
        // Back to search page
        this.$routerGo(this.parent.searchPageRouterName, null, {filter : this.filter})
      } else {
        this.item = this.items[this.itemIndex]
        this.inputBidPrice = this.getBidPrice()
        if (this.itemIndex === this.items.length - 1 && this.isMoreLimit) {
          this.itemSearchStore.searchItems(this.$router, this.items.map(it => it.exhibition_item_no))
        }
        this.refreshItemForDetail()
      }
    },
    downloadItemImages() {
      this.loadingStore.setLoading(true)
      const params = {
        exhibitionItemNo : this.item.exhibition_item_no
      }
      this.downloadImages(params).then(data => {
        this.loadingStore.setLoading(false)
        location.href = data.url
      })
        .catch(error => {
          this.loadingStore.setLoading(false)
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    refreshItemForDetail() {
      const params = {
        exhibitionItemNo : this.$route.params.id
      }
      this.loadingStore.setLoading(true)

      // Refresh Slider
      this.isSliderVisible = false

      this.refreshItem(params).then(data => {
        console.log('refreshItem:', data)
        this.loadingStore.setLoading(false)
        this.free_fields = data.free_fields
        this.item.images = (data.images || []).concat(data.item_images || []).concat(data.optional_images || [])
        this.item.itemImages = data.item_images ? data.item_images : this.item.itemImages
        this.item.optionalImages = data.optional_images ? data.optional_images : this.item.optionalImages
        this.item.pdfs = data.pdfs ? data.pdfs : this.item.pdfs
        this.bid_histories = data.bid_histories
        this.shipping_amount = data.shipping_amount
        this.has_shipping_address = data.has_shipping_address
        this.item.login_member_no = data.login_member_no

        // Refresh Slider
        const scriptSrc = `${process.env.VUE_APP_DESIGN_JAVASCRIPT_PATH}gallery/gallery.js`
        const scriptElm = document.querySelector(`script[src='${scriptSrc}']`)
        // Console.log('scriptElm: ', scriptElm)
        if (scriptElm) {
          document.head.removeChild(scriptElm)
        }
        this.isSliderVisible = true
        this.$nextTick(() => {
          Methods.addExternalScripts(['gallery/gallery.js'])
        })
      })
        .catch(error => {
          this.loadingStore.setLoading(false)
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    moment(val) {
      return moment(val)
    },
    getShareUrl() {
      const href = window.location.href
      const url = href.split('?')
      return url && url.length > 1 ? url[0] : href
    },
    getShareImageUrl() {
      let url = ''
      if (this.item.images && this.item.images.length > 0) {
        url = `${window.location.origin}/${encodeURIComponent(this.item.images[0].postar_file_path ? this.item.images[0].postar_file_path : this.item.images[0].file_path)}`
      }
      return url
    },
    nextBtnClicked() {
      this.$routerGo(this.$route.name, this.items[this.itemIndex + 1].exhibition_item_no, {filter : this.$route.query.filter})
      this.isShareThisVisible = false
      this.$nextTick(function() {
        this.isShareThisVisible = true
      })
    },
    prevBtnClicked() {
      this.$routerGo(this.$route.name, this.items[this.itemIndex - 1].exhibition_item_no, {filter : this.$route.query.filter})
      this.isShareThisVisible = false
      this.$nextTick(function() {
        this.isShareThisVisible = true
      })
    },
    videoPlaying(event) {
      console.log(event.type)
      if (event.target.ended) {
        console.log('ended -> re-load')
        event.target.load()
        event.target.play()
      }
    },
    taxPrice(price) {
      // 会員出品の場合はTaxしない
      if (this.item.exhibition_division === 1) {
        return price ? price.numberString() : '0'
      }
      return price ? this.itemSearchStore.getPriceIncludeTax(price) : '0'
    }
  },
  watch : {
    '$route.params.id'(value) {
      this.refreshItemForDetail()
      this.getCurrentItem()
      this.errors = []
    },
    '$i18n.locale'() {
      this.refreshItemForDetail()
    }
  }
})
</script>
<style scoped lang="scss">
  .my-gallery.slider-for.slick-initialized.slick-slider {
    min-height: 250px;
  }
  dl dd {
    line-break: anywhere;
  }
  .mech_d-spec-list {
    // padding-bottom: 40px !important;
  }
  #main #machine-data .mech_d-main .mech_d-main-txt p.lot-number {
    margin-right: 10px !important;
  }
  .mech_d-main .price-system {
    color: #364A81;
    font-size: 14px;
    font-weight: 700;
    background-color: #E8FEFF;
    border: 1px solid #364A81;
    border-radius: 100vh;
    min-width: 100px;
    text-align: center;
    height: 32px;
    line-height: 28px !important;
    padding: 0 10px;
    margin-bottom: 20px;
    margin-right: 5px;
  }
  .recommending-status {
    padding: 5px 13px;
    font-size: 14px;
    color: #fff;
    background-color: #E80000;
    text-align: center;
    border-radius: 100vh;
    font-weight: 700;
    margin-left: 5px;
    letter-spacing: 0;
    height: 32px;
    margin-bottom: 20px;
  }
  .txt-price {
    span {
      font-weight: 700;
    }
  }
  dd {
    span {
      font-weight: 700;
    }
  }
  .btn-reload {
    float: right;
    margin: 0 10px;
    text-align: right;
    img {
      width: 35px;
    }
    &.fix-width {
      width: calc(100% - 205px);
    }
  }
  .msg-normal {
    text-align: center;
  }
  .mech_d-main-price .status {
    justify-content: right !important;
  }
  .multi-free-field {
    border-bottom: 2px solid #EAEAEA;
  }
</style>
<style scoped lang="scss">
  #main #machine-data .mech_d-spec .mech_d-spec-note .mech_d-note-txt {
    width: 100% !important;
  }
</style>
<style scoped lang="scss">
  /* ---------------------------
  スペック
  ----------------------------- */
  #main #machine-data .mech_d-spec .mech_d-spec-list .detail-block {
    margin-top: 20px;
    font-size: 18px;
    width: 50%;
    padding: 0 40px;
    display: block;
  }
  #main #machine-data .mech_d-spec .mech_d-spec-list .detail-block dl {
    width: 100%;
  }

  @media only screen and (max-width: 767px) {
    #main #machine-data .mech_d-spec .mech_d-spec-list .detail-block {
      padding: 0 15px;
      font-size: 15px;
      display: block;
      margin-top: 8px;
      width: 100%;
    }
  }
</style>
<style scoped lang="scss">
  #main #sns-share > p {
    margin-right: 5px;
    text-align: center;
  }
  #main #sns-share {
    padding: 5px 5px 8px 5px !important;
  }
  @media only screen and (max-width: 767px) {
    #main .share-this {
      text-align: center;
    }
    #main #sns-share {
      padding: 5px 5px !important;
    }
    #main #sns-share > p {
      margin: 0 !important;
      text-align: center;
    }
  }
  @media only screen and (min-width: 768px) {
    #sns-share {
      width: 300px !important;
      flex-direction: column;
      border-radius: 0px !important;
    }
    #main #sns-share > p {
      margin-bottom: 5px !important;
      text-align: center;
    }
  }
</style>
<style scoped lang="scss">
  @media only screen and (max-width: 767px) {
    #main #terms p.tit-period {
      width: 100% !important;
    }
  }
</style>
<style scoped lang="scss">
  #main #machine-data .mech_d-main .mech_d-main-txt .mech_d-main-price .status p {
      margin-right: auto !important;
      margin-left: 1px !important;
  }
  .btn-reload {
    margin: 0;
    width: auto !important;
  }
  #main #machine-data .mech_d-main .mech_d-main-txt .mech_d-main-system .btn-box a.btn-signup img {
    margin-right: 10px;
  }
</style>
<style scoped lang="scss">
  #main #machine-data .mech_d-main .mech_d-main-visual ul.gallery-bottom {
    position: relative;
    left: 0;
    top: -125px;
    width: 100%;
  }
  #main #machine-data .mech_d-main .mech_d-main-visual ul.gallery-bottom li.item-set-text {
    font-size: 18px;
    font-weight: 700;
    color: #364A81;
    text-align: center;
    padding: 2px 15px;
    background-color: #EAEAEA;
    border-radius: 100vh;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    float:right;
  }
  @media only screen and (min-width: 768px) {
    #main #machine-data .mech_d-main .mech_d-main-visual ul.gallery-bottom {
      top: -125px;
    }
  }
  @media only screen and (max-width: 767px) {
    #main #machine-data .mech_d-main .mech_d-main-visual ul.gallery-bottom {
      top: -105px;
    }
    .my-gallery.slider-for.slick-initialized.slick-slider {
      min-height: 200px;
    }
  }
</style>
<style scoped lang="scss">
  @media only screen and (min-width: 768px) {
    .my-gallery.slider-for.slick-initialized.slick-slider img {
      width: 100vw;
    }
  }
</style>
<style scoped lang="scss">
  #main #machine-data .mech_d-main .mech_d-main-txt .mech_d-main-price .status p.msg-second {
    padding: 5px 13px;
    font-size: 16px;
    color: #000;
    background-color: #ced2d8;
    text-align: center;
  }
  #main #machine-data .mech_d-main .mech_d-main-txt .mech_d-main-price .status p.msg-red {
    flex-grow: 0 !important;
  }
  .mech_d-btn .pic {
    width: 240px;
  }
  .bidP-tax {
    font-size: 0.9em;
  }
  .bid-history {
    padding: 10px 30px 10px;
    background-color: #F7F7F7;
    border: none;
  }
  .bid-history-label {
    color: inherit;
    font-weight: 700;
    width: 100%;
    padding-right: 5px;
    margin-top: 5px;
  }
  #main #machine-data .mech_d-main .mech_d-main-txt .mech_d-main-price .status {
    padding: 10px 30px 10px !important;
    margin-top: 0 !important;
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
