import Methods from '@/api/methods'
import Base from '@/common/base'
import useItemSearch from '@/stores/useItemSearch'
import useLoading from '@/stores/useLoading'
import useSetting from '@/stores/useSetting'
import {defineComponent} from '@vue/composition-api'

export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const itemSearchStore = useItemSearch()
    const settingStore = useSetting()
    return {
      loadingStore,
      itemSearchStore,
      settingStore
    }
  },
  data() {
    return {
      confirmBidDialog         : false,
      bidConfirmSuccess        : false,
      bidConfirmSuccessMessage : '',
      bidConfirmDialogMode     : 1, // 1 Bid confirm, 2 Bid cancel
      bidConfirmItems          : [],
      bidConfirmMessages       : []
    }
  },
  methods : {
    openCancelBidDialog(errors) {
      console.log('openCancelBidDialog: ', errors)
      this.bidConfirmDialogMode = 2
      this.bidConfirmMessages = errors
      this.confirmBidDialog = true
    },
    openBidConfirmDialog(itemNo, newBidPrice) {
      console.log('openBidConfirmDialog', itemNo)
      this.bidConfirmDialogMode = 1
      this.confirmBidDialog = true
      this.bidConfirmSuccess = false
      this.bidConfirmMessages = []

      if (this.nameTag === 'AUCTION_DETAIL_PAGE_NAME' || this.nameTag === 'TENDER_DETAIL_PAGE_NAME') {
        const newPriceItem = Object.assign({}, this.item, {inputBidPrice : this.inputBidPrice})
        this.bidConfirmItems = [].concat(newPriceItem)
      } else {
        this.bidConfirmItems = this.items.filter(x => x.exhibition_item_no === itemNo).map(y => {
          y.inputBidPrice = newBidPrice
          return y
        })
      }
    },
    bidConfirm() {
      this.bidConfirmSuccessMessage = ''
      const items = this.bidConfirmItems.map(item => {
        return {
          exhibitionItemNo : item.exhibition_item_no,
          bidPrice         : item.inputBidPrice === '' ? null : (Base.localeString2Number(item.inputBidPrice) * Math.pow(10, String(item.bid_status.pitch_width).length - 1))
        }
      })
      this.bidItems(items)
        .then(data => {
          this.bidConfirmMessages = data.bidList.filter(bid => bid.errorMessage)
          if (this.bidConfirmMessages.length === 0) {
            this.bidConfirmItems = []
            /*
             * This.confirmBidDialog = false
             * 他の入札者がより高い金額で自動入札していた場合
             * 他の方がより高い金額で入札しています。と表示させるように
             */
            const tmp_item = data.bidList.filter(bid => !bid.errorMessage)
            if (tmp_item && tmp_item.length > 0
               && (!tmp_item[0].is_exceeding_lowest_price || tmp_item[0].is_top_member)) {
              this.bidConfirmSuccessMessage = this.$t('ITEM_ROW_BID_SUCCESS_MESSAGE')
            } else {
              this.bidConfirmSuccessMessage = this.$t('ITEM_ROW_BID_SUCCESS_MESSAGE_NOT_TOP')
            }
            this.bidConfirmSuccess = true
          }
        })
        .then(() => {
          // Save user setting
          return this.settingStore.updateSetting()
        })
        .catch(error => {
          this.loadingStore.setLoading(false)
          Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    bidItems(items) {
      const params = {
        bidItems : items
      }
      this.loadingStore.setLoading(true)
      return Methods.apiExcute('private/bid-items', this.$i18n.locale, params).then(data => {
        this.loadingStore.setLoading(false)
        this.itemSearchStore.updateViewAfterBid(data.bidList.filter(bid => !bid.errorMessage))
        return Promise.resolve(data)
      })
    },
    closeBidConfirmDialog() {
      this.confirmBidDialog = false
      // This.bidConfirmMessages = []
      this.bidConfirmItems = []
    }
  }
})
