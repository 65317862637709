<template>
  <Detail
  :search="searchTenderItems"
  :downloadImages="downloadImages"
  :favorite="favorite"
  :parent="$data"
  :refreshItem="refreshItem"
  />
</template>
<script>
import Methods from '@/api/methods'
import Detail from '../../components/ItemList/Detail'
export default {
  components : {
    Detail
  },
  data() {
    return {
      nameTag              : 'TENDER_DETAIL_PAGE_NAME',
      searchPageNameTag    : 'TENDER_PAGE_HEADER_LABEL',
      searchPageRouterName : this.$define.PAGE_NAMES.TENDER_LIST_PAGE_NAME,
      contactPageName      : this.$define.PAGE_NAMES.ITEM_CONTACT_PAGE_NAME,
      typeTag              : 'TENDER_PAGE_SEARCH_TYPE'
    }
  },
  methods : {
    searchTenderItems(params) {
      return Methods.apiExcute('public/search-auction-items', this.$i18n.locale, Object.assign({exhClass : 2}, params))
    },
    downloadImages(params) {
      return Methods.apiExcute('public/download-item-images', this.$i18n.locale, params)
    },
    favorite(params) {
      return Methods.apiExcute('private/favorite-item', this.$i18n.locale, params)
    },
    refreshItem(params) {
      return Methods.apiExcute('public/refresh-item', this.$i18n.locale, params)
    }
  }
}
</script>
