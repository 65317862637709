<template>
  <v-dialog v-model="confirmBidDialog" persistent content-class="overflow-visible bulk-bidding-dialog" style="z-index=5;" >
    <v-card>
      <v-divider></v-divider>
      <v-card-title>
        <div>{{$t("ITEM_SEARCH_CONFIRM_BIDDING_MODAL_HEADER")}}</div>
      </v-card-title>
      <v-card-text class="pt-3 overflow-y-auto result bulk-bidding-card-text" v-show="bidConfirmDialogMode === 1">
          <template v-if="!bidConfirmSuccess">
            <ul class="machine_list">
              <BidConfirmItem
                v-for="(item, index) in bidConfirmItems"
                :name="'bid_confirm_' + index"
                :key="item.exhibition_item_no"
                :item="item"
                :parent="parent"
                :grandfather="grandParent"
                :errorMessages="bidConfirmMessages.filter(error => error.exhibition_item_no == item.exhibition_item_no)"
                :favorite="favorite"
                :getPriceIncludeTax="getPriceIncludeTax"
                :getInspectionTxt="getInspectionTxt"
              />
            </ul>

            <RuleCheckBox
              class="px-3"
              ref="ruleCheckBox"
              @change="(val) => {ruleCheckBtnClicked = val}"
            >
              <p class="text-center form-privacy mt-3">
                入札いただく前に「
                <a
                  class="link-text"
                  @click="$routerGoNewWindow(
                    $define.PAGE_NAMES.OTHER_GUIDE_TERMS_PAGE_NAME,
                    null,
                    {lang: $i18n.locale}
                    )"
                >利用規約</a>
                」、「
                <a
                  class="link-text"
                  @click="$routerGoNewWindow(
                    $define.PAGE_NAMES.OTHER_GUIDE_AUCTION_TERMS_PAGE_NAME,
                    null,
                    {lang: $i18n.locale}
                    )"
                >オークション利用規約</a>
                」、「
                <a
                class="link-text"
                @click="$routerGoNewWindow(
                  $define.PAGE_NAMES.OTHER_GUIDE_COMMISSION_PAGE_NAME,
                  null,
                  {lang: $i18n.locale}
                  )"
                >各種手数料</a>
                」をご確認ください。
              </p>
            </RuleCheckBox>

            <div class="bid-confirm-message"><span>{{$t('ITEM_ROW_BID_CONFIRM_MESSAGE')}}</span></div>
            <div class="d-flex justify-center">
              <v-checkbox
                v-model="skipBidCOnfirm"
                @change="settingStore.setSkipBidConfirm"
              >
                <template v-slot:label>
                  <span class="skip-txt">{{ $t('ITEM_ROW_BID_CONFIRM_SKIP') }}</span>
                </template>
              </v-checkbox>
            </div>
            <div class="bidP-enter">
              <button @click="bidConfirm" :disabled="!isBiddable || !ruleCheckBtnClicked">{{$t("ITEM_SEARCH_BULK_BIDDING_MODAL_BID_BUTTON_LABEL")}}</button>
            </div>
          </template>
          <template v-else>
            <div class="bid-confirm-message">
              <span v-bind:class="{'success_message_small': bidConfirmSuccessMessage === $t('ITEM_ROW_BID_SUCCESS_MESSAGE_NOT_TOP')}">
                {{bidConfirmSuccessMessage}}
              </span>
            </div>
            <div class="bidP-enter">
              <button
                class="btn modal_comfirm_btn"
                @click="closeBidConfirmDialog"
              >
                {{$t('ITEM_SEARCH_CONFIRM_BIDDING_MODAL_CANCEL_BUTTON_LABEL')}}
              </button>
            </div>
          </template>
      </v-card-text>
      <v-card-text class="pt-3 overflow-y-auto bulk-bidding-card-text" v-show="bidConfirmDialogMode !== 1">
          <div v-if="errors===null || errors.length === 0">
            <div class="bid-confirm-message">
              <span>{{ $t('ITEM_SEARCH_CONFIRM_BIDDING_LOGIN_REQUIRED_LABEL') }}</span>
            </div>
            <div class="bidP-enter">
              <button
                class="btn modal_comfirm_btn"
                @click="$routerGo($define.PAGE_NAMES.LOGIN_PAGE_NAME, null, {next: $route.name, id: $route.params.id, filter: parent.filter})"
              >
                {{$t("ITEM_SEARCH_BIDDING_MODAL_LOGIN_BUTTON_LABEL")}}
              </button>
            </div>
          </div>
          <div v-else>
            <div class="bid-confirm-message">
              <span v-for="(err, index) in errors" :key="index" :class="err.success ? '' : 'txt-err'">{{err.errorMessage}}</span>
            </div>
            <div class="bidP-enter">
              <button
                class="btn modal_comfirm_btn"
                @click="closeBidConfirmDialog"
              >
                {{$t("ITEM_SEARCH_CONFIRM_BIDDING_MODAL_CANCEL_BUTTON_LABEL")}}
              </button>
            </div>
          </div>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <button @click="closeBidConfirmDialog" class="remodal-close"></button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DownloadFile from '@/common/downloadFile'
import RuleCheckBox from '@/components/Checkbox/RuleCheckBox'
import useSetting from '@/stores/useSetting'
import {defineComponent} from '@vue/composition-api'
import BidConfirmItem from './BidConfirmItem'
export default defineComponent({
  components : {
    BidConfirmItem,
    RuleCheckBox
  },
  setup() {
    const settingStore = useSetting()
    return {
      settingStore
    }
  },
  props : {
    parent : {
      type    : Object,
      default : Object
    },
    grandParent : {
      type    : Object,
      default : Object
    },
    confirmBidDialog : {
      type    : Boolean,
      default : false
    },
    bidConfirmItems : {
      type    : Array,
      default : () => []
    },
    bidConfirm : {
      type    : Function,
      default : Object
    },
    bidConfirmMessages : {
      type    : Array,
      default : () => []
    },
    closeBidConfirmDialog : {
      type    : Function,
      default : Object
    },
    getPriceIncludeTax : {
      type    : Function,
      default : Object
    },
    favorite : {
      type    : Function,
      default : Object
    },
    errors : {
      type    : Array,
      default : null
    },
    bidConfirmSuccess : {
      type    : Boolean,
      default : false
    },
    bidConfirmDialogMode : {
      type    : Number,
      default : 1
    },
    bidConfirmSuccessMessage : {
      type    : String,
      default : ''
    },
    getInspectionTxt : {
      type    : Function,
      default : Object
    },
  },
  mixins  : [DownloadFile],
  mounted() {},
  data() {
    return {
      ruleCheckBtnClicked : false
    }
  },
  watch : {
    confirmBidDialog(val) {
      if (val) {
        this.$nextTick(() => {
          this.ruleCheckBtnClicked = false
          this.$refs.ruleCheckBox?.resetRuleCheck()
        })
      }
    }
  },
  methods : {
    priceCheckedClick() {
      // ボタンの活性・非活性
      if (document.getElementById('comfirm_check').checked === false) {
        console.log('uncheck')
        document.getElementById('bid_btn').disabled = true
      } else {
        console.log('checked')
        document.getElementById('bid_btn').disabled = false
      }
    },
  },
  computed : {
    skipBidCOnfirm : {
      get() {
        return this.settingStore.skipBidCOnfirm
      },
      set(val) {
        console.log('setSkipBidConfirm val:', val)
        this.settingStore.setSkipBidConfirm(val)
      }
    },
    isBiddable() {
      // Const isSkipBidConfirm = this.settingStore.isSkipBidConfirm
      const memberNo = this.$cookies.get(this.$define.LOCAL_STORE_LABEL.MEMBER_NO)
      const isSameMember = this.bidConfirmItems.some(x => x.exhibition_division === 1 && x.exhibition_member_no && String(x.exhibition_member_no) === String(memberNo))
      return !isSameMember
    }
  }
})
</script>
<style lang="scss" scoped>
  @media only screen and (max-width: 767px) {
    .bulk-bidding-dialog {
      margin: 0px !important;
      padding: 10px;
      .bidP-enter button {
        width: 90%;
      }
    }
  }
  @media only screen and (min-width: 768px) {
    .bulk-bidding-dialog {
      max-width: 1210px;
      padding: 20px;
      .bidP-enter button {
        width: 320px;
      }
      ul.machine_list > li {
        margin-bottom: 30px;
        margin-top: 0px;
      }
    }
  }

  .bulk-bidding-dialog .bidP-enter button {
    background-position: center right 15% !important;
  }
  .remodal-close {
    position: absolute;
    top: -18px;
    right: -18px;
    display: block;
    overflow: visible;
    width: 60px;
    height: 60px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
    text-decoration: none;

    color: #fff;
    border: 0;
    outline: 0;
    background-color: #364A81;
    border-radius: 50% !important;
  }

  .remodal-close:hover,
  .remodal-close:focus {
    color: #fff;
  }

  .remodal-close:before {
    font-size: 25px;
    line-height: 58px;

    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 60px;

    content: "×";
    text-align: center;
  }
  @media only screen and (max-width: 767px) {
    .remodal-close {
      width: 40px;
      height: 40px;
      top: -5px;
      right: -5px;
    }
    .remodal-close:before {
      font-size: 20px;
      width: 40px;
      line-height: 40px;
    }
  }
  .com-item-box {
    width: 100%
  }
  .bid-confirm-message {
  width: 100%;
  text-align: center !important;
  font-size: 20px !important;
  font-weight: 150;
  margin-top: 25px !important;
  margin-bottom: 25px !important;
  padding-right: 5px;
  padding-left: 5px;
}
.bid-confirm-success {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #248f48;
    font-size: 40px !important;
    font-weight: 600 !important;
}
</style>
<style lang="scss" scoped>
  .bulk-bidding-dialog .bidP-enter {
    margin-top: 10px;
    width: 100%;
    text-align: center;
  }
  .bulk-bidding-dialog .bidP-enter button {
    font-size: 22px;
    font-weight: 700;
    background: #fff url("../../../assets/img/common/ic_arrow_black.svg") center right 100px / 19px auto no-repeat;
    border: 2px solid #000;
    border-bottom-width: 4px;
    border-radius: 100vh;
    height: 60px;
    transition: all 0.08s linear;
  }
  .bulk-bidding-dialog .bidP-enter button:hover {
    background-color: #000;
    background-image: url("../../../assets/img/common/ic_arrow_white.svg");
    color: #fff;
  }
  .bulk-bidding-dialog .bidP-enter button:disabled {
    opacity: 0.7;
    background-color: #c4c4c4;
    background-image: url("../../../assets/img/common/ic_arrow_black.svg");
    color: #000;
  }
  .bulk-bidding-dialog .bidP-enter {
    margin-top: 20px;
  }
  .bulk-bidding-dialog .bidP-enter button {
    background-position: center right 15%;
  }
  .skip-txt {
    color: #364A81;
    font-weight: 600;
    font-size: 1.2em;
  }

  @media only screen and (max-width: 767px) {
    .v-dialog > .v-card > .v-card__text {
        padding: 0 !important;
    }
    .v-dialog > .v-card > .v-card__text {
      overflow-y: scroll;
      max-height: calc(100vh - 150px);
    }
  }
</style>

<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
