<template>
<countdown :left-time="1000*Number(remainingSeconds)" :auto-start="true">
  <div
    class="fade"
    slot="process"
    slot-scope="model">{{$t('DETAIL_ITEM_BID_REMAINING_TIME')}}: {{ timeRemainingCal(model.timeObj.ceil.s) }}</div>
  <div slot="finish">
    <p class="countdown-text">{{
      remainingSeconds > 0 ? ($t('DETAIL_ITEM_BID_REMAINING_TIME') + ': 0:00') : ($t('DETAIL_ITEM_BID_EXTENDED_END') + ' ' + $options.filters.dateTimeFormat(endDatetime))
    }}</p>
  </div>
</countdown>
</template>

<script>
import commonFilters from '@/common/commonFilters'
import {defineComponent} from '@vue/composition-api'
export default defineComponent({
  props : {
    remainingSeconds : {
      type    : Number,
      default : Object
    },
    endDatetime : {
      type    : String,
      default : ''
    }
  },
  mixins  : [commonFilters],
  methods : {
    timeRemainingCal(seconds) {
      let result = ''
      if (seconds > 0) {
        const day = Math.floor(((seconds / 60) / 60) / 24)
        const hour = Math.floor(((seconds / 60) / 60) % 24)
        const min = Math.floor((seconds / 60) % 60)
        const sec = Math.floor(seconds % 60)
        if (day === 0) {
          result += ''
        } else if (day < 2) {
          result += `${day}日 `
        } else {
          result += `${day}日 `
        }
        if (hour < 10) {
          result += `0${hour}:`
        } else {
          result += `${hour}:`
        }
        if (min < 10) {
          result += `0${min}:`
        } else {
          result += `${min}:`
        }
        if (sec < 10) {
          result += `0${sec}`
        } else {
          result += sec
        }
      }
      return result
    }
  }
})
</script>
<style scoped lang="scss">
div.fade {
  animation: blink 1s ease-in-out infinite alternate;
  text-align: center;
}

@keyframes blink {
  from { color: #7D7D7D; }
  to { color: red; }
}

.countdown-text {
  font-size: 14px;
  font-weight: 700;
  color: #7D7D7D;
  text-align: center;
}
</style>
