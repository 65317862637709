import Methods from '@/api/methods'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'
export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    return {
      loadingStore,
    }
  },
  data() {
    return {
      sampleFile : 'static/sample.xlsx',
    }
  },
  methods : {
    downloadFile(key) {
      if (key) {
        window.open(process.env.VUE_APP_API_ENDPOINT.replace('api/', '') + encodeURIComponent(key), '_blank')
      }
    },
    downloadFileS3(key) {
      this.loadingStore.setLoading(true)
      Methods.apiExcute('private/download-file', this.$i18n.locale, {
        key
      }).then(data => {
        this.loadingStore.setLoading(false)
        console.log(`data: ${JSON.stringify(data)}`)
        window.open(data, '_blank')
      })
        .catch(error => {
          this.loadingStore.setLoading(false)
          Methods.parseHtmlResponseError(this.$router, error)
        })
    }
  },
})
