<template>
    <!-- Root element of PhotoSwipe. Must have class pswp. -->
    <div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">

        <!-- Background of PhotoSwipe.
            It's a separate element as animating opacity is faster than rgba(). -->
        <div class="pswp__bg"></div>

        <!-- Slides wrapper with overflow:hidden. -->
        <div class="pswp__scroll-wrap">

            <!-- Container that holds slides.
                PhotoSwipe keeps only 3 of them in the DOM to save memory.
                Don't modify these 3 pswp__item elements, data is added later on. -->
            <div class="pswp__container">
                <div class="pswp__item"></div>
                <div class="pswp__item"></div>
                <div class="pswp__item"></div>
            </div>

            <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
            <div class="pswp__ui pswp__ui--hidden">

                <div class="pswp__top-bar">

                    <!--  Controls are self-explanatory. Order can be changed. -->

                    <div class="pswp__counter"></div>

                    <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>

                    <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>

                    <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>

                    <!-- Preloader demo http://codepen.io/dimsemenov/pen/yyBWoR -->
                    <!-- element will get class pswp__preloader--active when preloader is running -->
                    <div class="pswp__preloader">
                        <div class="pswp__preloader__icn">
                        <div class="pswp__preloader__cut">
                            <div class="pswp__preloader__donut"></div>
                        </div>
                        </div>
                    </div>
                </div>

                <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                    <div class="pswp__share-tooltip"></div>
                </div>

                <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
                </button>

                <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
                </button>

                <div class="pswp__caption">
                    <div class="pswp__caption__center"></div>
                </div>

            </div>

        </div>

    </div>
</template>

<script>
import 'photoswipe/dist/default-skin/default-skin.css'
import 'photoswipe/dist/photoswipe.css'

import PhotoSwipe from 'photoswipe/dist/photoswipe'
import PhotoSwipeDefaultUI from 'photoswipe/dist/photoswipe-ui-default'

export default {
  methods : {
    onSlideClick(event, index, items) {
      event.preventDefault()
      this.open(index, items)
    },
    open(index, items, options = {
      // CaptionEl: false,
      fullscreenEl  : true,
      history       : false,
      shareEl       : false,
      closeOnScroll : false,
      pinchToClose  : false,
      scaleMode     : 'fit'
    }) {
      const $gallery = document.querySelector('.my-gallery')
      const $pswpElement = document.querySelector('.pswp')
      if (!$gallery || !$pswpElement) return
      let imgItems = Array.prototype.map.call(
        $gallery.querySelectorAll('figure:not(.slick-cloned) > a'),
        $a => {
          const size = $a.getAttribute('data-size').split('x')
          const img = $a.querySelector('img')
          const index = $a.parentElement.getAttribute('data-slick-index')
          return {
            index,
            src   : $a.getAttribute('href'),
            w     : 0, // Img && img.clientWidth ? img.clientWidth : parseInt(size[0], 10),
            h     : 0, // Img && img.clientHeight ? img.clientHeight : parseInt(size[1], 10),
            el    : $a.parentElement,
          }
        }
      )
      imgItems = imgItems ? imgItems.filter(x => x !== null) : []
      const opts = Object.assign({
        index,
        getThumbBoundsFn(index) {
          const thumbnail = document.querySelectorAll('img')[index]
          const pageYScroll = window.pageYOffset
                                || document.documentElement.scrollTop
                                || document.body.scrollTop
                                || 0
          const rect = thumbnail.getBoundingClientRect()
          return {
            x : rect.left,
            y : rect.top + pageYScroll,
            w : rect.width
          }
        }
      }, options)

      this.photoswipe = new PhotoSwipe($pswpElement, PhotoSwipeDefaultUI, imgItems, opts)

      this.photoswipe.listen('gettingData', (index, item) => {
        if (item.w < 1 || item.h < 1) { // Unknown size
          const img = new Image()
          const gallery = this.photoswipe
          img.onload = function() { // Will get size after load
            item.w = this.width // Set image width
            item.h = this.height // Set image height
            gallery.invalidateCurrItems() // Reinit Items
            gallery.updateSize(true) // Reinit Items
          }
          img.src = item.src // Let's download image
        }
      })

      this.photoswipe.init()
    },

    close() {
      this.photoswipe.close()
    }
  }
}
</script>
