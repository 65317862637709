<template>
<li v-bind:class="{'new-item': item.is_new}">
  <div class="machine_li-img">
    <a
     class="machine_li-img-wrap com-item-box unlink"
    >
      <img class="item-box-image" v-if="item.images && item.images.length > 0 && item.images.find(file => !file.postar_file_path && file.file_path)"
        :src="(item.images.find(file => !file.postar_file_path && file.file_path).file_path) | getFullImagePath"  alt="">
      <img class="item-box-image" v-else src="@/assets/img/no_photo.jpg" alt="">
      <!-- <span v-if="item.attention_info" v-bind:class="{'fav-mark': true, 'active': item.attention_info.is_favorited}"></span> -->
      <img v-if="item.sold_out" class="sold-badge" src="@/assets/img/common/img_soldout_s.png" alt="SOLD OUT">
      <ul class="status">
        <li class="top_status" v-if="item.bid_status && item.bid_status.is_top_member">{{$t("ITEM_ROW_TOP_STATUS_LABEL")}}</li>
        <li class="second_status" v-if="item.bid_status && item.bid_status.is_second_member">{{$t("ITEM_ROW_SECOND_STATUS_LABEL")}}</li>
        <li v-if="item.bid_status && item.bid_status.is_exceeding_lowest_price">{{$t("ITEM_ROW_EXCEEDING_THE_LOWEST_BID_STATUS_LABEL")}}</li>
        <li v-if="item.bid_status && item.bid_status.is_more_little">{{$t("ITEM_ROW_MORE_LITTLE_LABEL")}}</li>
        <li v-if="item.bid_status && item.bid_status.is_cancel">{{$t("ITEM_ROW_CANCEL_LABEL")}}</li>
      </ul>
      <ul v-if="item.item_count && item.item_count > 1" class="item-set">
        <li>{{item.item_count + $t("ITEM_ROW_MULTIPLE_ITEMS_LABEL")}}</li>
      </ul>
    </a>
  </div>
  <div class="machine_li-txt">
    <p class="stock-location" v-if="!item.exhibition_member_no && item.exhibition_division === 2">{{ $t('ITEM_ROW_EXHIBIT_UEJ_LABEL') }}</p>
    <p class="stock-location" v-if="item.exhibition_member_no && item.exhibition_division === 2">{{ $t('ITEM_ROW_EXHIBIT_UEJ_LABEL') }}</p>
    <p class="stock-location" v-if="item.exhibition_division === 1">{{ $t('ITEM_ROW_EXHIBIT_MEMBER_LABEL') }}</p>
    <p v-if="item.free_field.makerName" class="price-system">{{ item.free_field.makerName }}</p>
    <div class="mach-data">
      <dl>
        <dt>{{$t("ITEM_ROW_BIKE_TYPE_LABEL")}}</dt>
        <dd class="line-break">{{item.free_field.bike_type}}</dd>
      </dl>
      <dl>
        <dt class="mw-5">{{$t("ITEM_ROW_CUBIC_CAPACITY")}}</dt>
        <dd>{{item.free_field.cubic_capacity_other | getCubicText($t('ITEM_FREE_FIELD_FUMEI'))}}</dd>
      </dl>
      <dl>
        <dt style="min-width:fit-content;">{{$t("ITEM_ROW_INSPECTION_DATE")}}</dt>
        <dd>{{getInspectionTxt(item.free_field.inspec_expire, item.free_field.inspec_expire_other)}}</dd>
      </dl>
      <dl>
        <dt class="mw-5">{{$t("ITEM_ROW_MILEAGE")}}</dt>
        <dd class="line-break">{{item.free_field.mileage_other || $t('ITEM_FREE_FIELD_FUMEI')}}</dd>
      </dl>
    </div>
    <div class="mach-price" v-if="item.bid_status">
      <dl v-if="$route.name==$define.PAGE_NAMES.TENDER_LIST_PAGE_NAME">
        <dt style="width:6em;">{{$t("DETAIL_LOWEST_BID_ACCEPT_PRICE_LABEL")}}</dt>
        <dd v-if="item.sold_out">{{$t("ITEM_ROW_SOLD_OUT_LABEL")}}</dd>
        <dd style="white-space: nowrap;" v-else>{{$t("ITEM_ROW_PRICE_CURRENCY_MARK")}}{{item.bid_status.lowest_bid_price.numberString()}}</dd>
      </dl>
      <dl v-else>
        <dt v-if="item.attention_info.bid_count > 0">{{$t("ITEM_ROW_CURRENT_PRICE_LABEL")}}</dt>
        <dt v-else style="width:6em;">{{$t("ITEM_ROW_START_PRICE_LABEL")}}</dt>
        <dd v-if="item.sold_out">{{$t("ITEM_ROW_SOLD_OUT_LABEL")}}</dd>
        <dd style="white-space: nowrap;" v-else>{{$t("ITEM_ROW_PRICE_CURRENCY_MARK")}}{{item.bid_status.current_price.numberString()}}</dd>
      </dl>
      <!-- <div class="count-down-box">
        <p class="fin" v-if="item.bid_status.extending">
          <ExtendCountDown
          :remainingSeconds="item.bid_status.remaining_seconds"
          :endDatetime="item.bid_status.end_datetime"
          :key="item.bid_status.remaining_seconds"/>
        </p>
        <p class="fin" v-else-if="item.bid_status.started">{{item.bid_status.remaining_seconds > 0 ? $t("ITEM_ROW_END_STATUS_LABEL") : $t("ITEM_ROW_ENDED_STATUS_LABEL")}} {{item.end_datetime | moment($t('ROW_BID_DATE_TIME_FORMAT'))}}</p>
        <p class="fin" v-else>{{$t("ITEM_ROW_START_STATUS_LABEL")}} {{item.start_datetime | moment($t('ROW_BID_DATE_TIME_FORMAT'))}}</p>
        <ul class="action-item" v-if="item.attention_info">
          <li v-if="item.attention_info.view_count" class="action-view"><img src="@/assets/img/common/ic_view.svg" alt=""><span>{{item.attention_info.view_count}}</span></li>
          <li v-if="item.attention_info.favorited_count" class="action-fav"><img src="@/assets/img/common/ic_stars.svg" alt=""><span>{{item.attention_info.favorited_count}}</span></li>
          <li v-if="item.attention_info.bid_count" class="action-bid"><img src="@/assets/img/common/ic_check.svg" alt=""><span>{{item.attention_info.bid_count}}</span></li>
        </ul>
      </div> -->
    </div>
  </div>
  <div class="machine_li-price" v-if="$route.name == $define.PAGE_NAMES.STOCK_LIST_PAGE_NAME">
    <div class="machine_li-price">
      <div class="sale-price">
        <p class="tit-sale">{{$t("ITEM_ROW_SELLING_PRICE_LABEL")}}</p>
        <p class="txt-price" v-if="item.free_field || (item.attention_info && item.price_display_flag == 0)">{{item.sold_out ? $t("ITEM_ROW_SOLD_OUT_LABEL") : 'ASK'}}</p>
        <p class="txt-price" v-else>
          <span v-if="item.sold_out" class="current-p">{{$t("ITEM_ROW_SOLD_OUT_LABEL")}}</span>
          <span v-else-if="item.attention_info">{{$t("ITEM_ROW_PRICE_CURRENCY_MARK")}}{{item.free_field.NEWSPRC.numberString()}}</span>
          <span v-else>{{item.free_field.NEWSPRC.numberString()}}</span>
        </p>
      </div>
      <ul class="reaction" v-if="item.attention_info">
        <li v-if="item.attention_info.view_count" class="action-view"><img src="@/assets/img/common/ic_view.svg" alt=""><span>{{item.attention_info.view_count}}</span></li>
        <li v-if="item.attention_info.favorited_count" class="action-fav"><img src="@/assets/img/common/ic_stars.svg" alt=""><span>{{item.attention_info.favorited_count}}</span></li>
        <li v-if="item.attention_info.bid_count" class="action-bid"><img src="@/assets/img/common/ic_check.svg" alt=""><span>{{item.attention_info.bid_count}}</span></li>
      </ul>
    </div>
  </div>
  <div class="machine_li-price" v-else>
    <div class="bidForm" v-if="item.bid_status">
      <v-tooltip top :color="((errors.length > 0 && errors[0].success) ? 'success' : 'error')" v-model="errors.length">
      <template v-slot:activator="{ on, attrs }">
        <div>
          <form v-on:submit.prevent="" v-if="item.bid_status.can_bid || item.sold_out">
            <div class="modal-error-message" v-if="errorMessages.length>0">
              <div v-for="(message,index) in errorMessages" :key="index">{{message.errorMessage}}</div>
            </div>
            <div class="bidPrice">
              <p class="bidP-tit">{{$t("ITEM_ROW_BID_PRICE_LABEL")}}</p>
              <div class="bidP-txt">
                <span class="yen">{{$t("ITEM_ROW_PRICE_CURRENCY_MARK")}}</span>
                <span class="lbl-price">{{inputBidPrice}}</span>
              </div>
            </div>
            <div class="bidPrice float-right">
              <span class="bidP-tax">
                （{{$t('ITEM_ROW_PRICE_WITH_TAX_LABEL')}} <span class=""> {{$t("ITEM_ROW_PRICE_CURRENCY_MARK")}}{{taxPrice}}</span>）
              </span>
            </div>
          </form>
          <div v-if="item.sold_out" class="sold-gray">
            <img src="@/assets/img/common/img_soldout_m.png" alt="SOLD OUT">
          </div>
        </div>
      </template>
      <div :class="errors.length>0 && errors[0].success ? 'custom-tooltip': ''">
        <div style="padding: 10px;" v-if="errors.length>0">
          <div v-for="(message,index) in errors" :key="index">{{message.errorMessage}}</div>
        </div>
      </div>
    </v-tooltip>
    </div>
    <div class="member" v-else>
      <p class="tit-member">{{$t("ITEM_ROW_MEMBERS_ONLY_LABEL")}}</p>
      <a class="btnBsc-Black" @click="$routerGo($define.PAGE_NAMES.LOGIN_PAGE_NAME, null, {next: $route.name, id: $route.params.id, filter: $route.query.filter})">{{$t("ITEM_ROW_LOGIN_LINK_LABEL")}}<img src="@/assets/img/common/ic_arrow_white.svg" alt=""></a>
      <a class="btnBsc-CoCor btn-entry" @click="$routerGo($define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME)"><span>{{$t('ITEM_ROW_NEW_MEMBER_REGISTRATION_BUTTON_LABEL')}}</span><img src="@/assets/img/common/ic_arrow_white.svg" alt=""></a>
    </div>
  </div>
  <div class="btn-detail-disabled"></div>
</li>
</template>
<script>
import Base from '@/common/base'
import CommonFilters from '@/common/commonFilters'
import {defineComponent} from '@vue/composition-api'
import ExtendCountDown from './ExtendCountDown'
export default defineComponent({
  components : {
    ExtendCountDown
  },
  props : {
    parent : {
      type    : Object,
      default : Object
    },
    grandfather : {
      type    : Object,
      default : Object
    },
    item : {
      type    : Object,
      default : Object
    },
    errorMessages : {
      type    : Array,
      default : () => []
    },
    favorite : {
      type    : Function,
      default : Object
    },
    getPriceIncludeTax : {
      type    : Function,
      default : Object
    },
    getInspectionTxt : {
      type    : Function,
      default : Object
    },
  },
  mixins : [CommonFilters],
  data() {
    return {
      errors        : [],
    }
  },
  /*
   * Created() {
   *   console.log('created')
   *   this.itemRowStore.inputBidPrice = this.itemRowStore.getBidPrice()
   * },
   */
  computed : {
    inputBidPrice() {
      return this.bidConfirmPrice(this.item.inputBidPrice)
    },
    taxPrice() {
      // 会員出品の場合はTaxしない
      if (this.item.exhibition_division === 1) {
        return this.item && this.item.inputBidPrice ? this.bidConfirmPrice(this.item.inputBidPrice) : '0'
      }
      return this.item && this.item.inputBidPrice ? this.getPriceIncludeTax(this.bidConfirmPrice(this.item.inputBidPrice)) : '0'
    }
  },
  methods : {
    bidConfirmPrice(value) {
      const num = this.getRealBidPrice(Base.localeString2Number(value))
      const commaPrice = num.toLocaleString()
      return commaPrice
    },
    getRealBidPrice(value) {
      const pitch_width = this.item && this.item.bid_status && this.item.bid_status.pitch_width ? this.item.bid_status.pitch_width : 0
      return value * Math.pow(10, String(pitch_width).length - 1)
    },
  },
  watch : {
  }
})
</script>
<style scoped lang="scss">
  .lbl-price {
    font-size: 32px;
    font-weight: 700;
    margin-left: 10px;
    margin-right: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .unlink {
    cursor: inherit;
  }
</style>
<style scoped lang="scss">
  .status .second_status {
    color: #000 !important;
    background-color: #ced2d8 !important;
    text-align: center !important;
    flex-grow: 2;
  }
</style>
<style scoped lang="scss">
  ul.machine_list > li .machine_li-txt .mach-data dl dd.line-break {
    line-break: anywhere !important;
  }
  .mw-5 {
    min-width: 5em !important;
  }
  .bidP-tax {
    margin-top: 10px;
  }

  .recommending-status {
    padding: 5px 13px;
    font-size: 14px;
    color: #fff;
    background-color: #E80000;
    text-align: center;
    border-radius: 100vh;
    font-weight: 700;
    margin-left: 5px;
    letter-spacing: 0;
    height: 32px;
    margin-bottom: 20px;
  }
  .price-system {
    color: #364A81;
    font-size: 14px;
    font-weight: 700;
    background-color: #E8FEFF;
    border: 1px solid #364A81;
    border-radius: 100vh;
    min-width: 100px;
    text-align: center;
    height: 32px;
    line-height: 28px !important;
    padding: 0 10px;
    margin-bottom: 20px;
    margin-right: 0;
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
