<template>
<div class="mech_d-main-visual">
  <div class="slider_wrap">
    <div class="my-gallery slider-for">
      <template v-if="!images || images.length === 0">
        <figure class="slide-item" style="width: 705px; height: 529px; background-color: #F7F7F7;">
          <a data-size="705x529" class="d-flex align-center justify-center" style="width: 100%; height: 100%;">
            <span class="text-h4">NO PHOTO</span>
          </a>
        </figure>
      </template>
      <template v-else>
        <figure v-for="(image, index) in images" :key="index" class="slide-item">
          <video
              v-if="image.postar_file_path"
              controls
              style="width:100%;height:100%;"
              preload="auto"
              @playing="videoPlaying($event)"
              @click.stop=""
              >
              <source :src="image.file_path | getFullImagePath" type="video/mp4">
          </video>
          <a v-else
            :href="image.file_path | getFullImagePath"
            data-size="705x529"
            class="d-flex align-center justify-center"
            style="width: 100%; height: 100%;"
            @click="$refs.photoswipe.onSlideClick($event, index, images)"
          >
            <img
              :data-lazy="image.file_path | getFullImagePath"
              alt=""
              style="object-fit: contain; font-family: 'object-fit: contain;';"
              @click.stop="$refs.photoswipe.onSlideClick($event, index, images)"
            >
          </a>
        </figure>
      </template>
    </div>
    <ul class="slider-nav" id="slider-nav-tags" v-if="images && images.length > 1">
      <li v-for="image in images" :key="image.file_path" class="thumbnail-item">
        <img v-if="image.postar_file_path" :data-lazy="image.postar_file_path | getFullImagePath" alt="">
        <img v-else :data-lazy="image.file_path | getFullImagePath" alt="">
      </li>
    </ul>
    <ul class="gallery-bottom">
      <!-- <li v-if="(free_fields && free_fields.length > 1)" class="item-set-text">{{free_fields.length + $t("ITEM_ROW_MULTIPLE_ITEMS_LABEL")}}</li> -->
    </ul>
  </div>

  <PhotoSwipe ref="photoswipe"/>
</div>
</template>

<script>
import Methods from '@/api/methods'
import '@/assets/css/gallery/slick.css'
import CommonFilters from '@/common/commonFilters'
import {defineComponent} from '@vue/composition-api'
import PhotoSwipe from './PhotoSwipe.vue'

export default defineComponent({
  components : {
    PhotoSwipe
  },
  mixins : [CommonFilters],
  props  : {
    images : {
      type : Array
    },
    exhibitionItemNo : {
      type    : String,
      default : null
    },
  },
  data() {
    return {
      scripts : [
        'prev-next.js',
        'gallery/ofi.js',
      ],
    }
  },
  mounted() {
    Methods.addExternalScripts(this.scripts)
  },
  computed : {
  },
  methods : {
  }
})
</script>

<style scoped lang="scss">
  .slide-item {
    height: 529px;
  }
  @media only screen and (max-width: 767px) {
    .slide-item {
      height: 100%;
    }
  }
</style>
<style src="@/assets/css/gallery/slick.css"></style>
<style src="@/assets/css/gallery/slick-theme.css"></style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
