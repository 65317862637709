<template>
  <div>
    <a
      v-if="fileInfo.key"
      @click="fileNameClickEvent(fileInfo.key)"
      href="javascript:void(0);"
      :style="fileInfo.key ? '' : 'color: inherit; text-decoration: inherit;'"
      v-bind:class="{'link': fileInfo.key}"
    >
      {{ fileName }}
    </a>
    <span v-else>{{nullText}}</span>
  </div>
</template>

<script>
import Base from '@/common/base'
import FileService from '@/common/downloadFile'

export default {
  mixins : [FileService],
  props      : {
    title : {
      type    : String,
      default : ''
    },
    fileInfo : {
      type    : Object,
      default : () => {}
    },
    nullText : {
      type    : String,
      default : 'あとで提出する'
    }
  },
  data() {
    return {
      // ImagePreviewUrl : null
    }
  },
  created() {
    console.log('fileInfo: ', this.fileInfo)
  },
  computed : {
    fileName() {
      return this.title || (this.fileInfo.key ? Base.getFileName(String(this.fileInfo.key)) : this.nullText)
    }
  },
  methods : {
    fileNameClickEvent(key) {
      console.log('fileNameClickEvent')
      // Filename click event handler
      if (key) {
        this.downloadFileS3(String(key))
      }
    },
  },
}
</script>
<style scoped lang="scss">
  .close-button {
    position: absolute;
    z-index: 1;
    margin: auto;
  }
  .files-flex-list-box .image {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .btn-file {
    width: 100%;
    margin: 0.25rem !important;
  }
</style>
