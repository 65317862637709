<template>
<form class="entry-result mt-0" id="entry-form">
  <div class="entry-formInner bgLGray">
    <DetailItemImageView
      :item-images="itemImages"
      :optional-images="optionalImages"
    />

    <DetailDocumentFiles :documentFiles="freeField.documentFilePath"/>

    <div class="form-wrap">
      <p class="form-wrap-tit">
        {{ $t('INSPECTION_STEP2_MAKER') }}
      </p>
      <div class="form-wrap-input">
        {{ constants.SELECT_MAKER.filter(x => String(x.value) === String(freeField.maker)).map(x => x.label).join() }}
      </div>
    </div>
    <div class="form-wrap">
      <p class="form-wrap-tit">
        {{ $t('INSPECTION_STEP2_BODY_TYPE') }}
      </p>
      <div class="form-wrap-input">
        <p
          v-for="typ in (freeField.body_types || [])"
          :key="typ"
        >
          {{ constants.SELECT_BODY_TYPE.filter(x => String(typ) === String(x.value)).map(x => x.label).join() }}
        </p>
      </div>
    </div>
    <div class="form-wrap">
      <p class="form-wrap-tit">
        {{ $t('INSPECTION_STEP2_BIKE_TYPE') }}
      </p>
      <div class="form-wrap-input">
        {{ freeField.bike_type }}
      </div>
    </div>
    <div class="form-wrap">
      <p class="form-wrap-tit">
        {{ $t('INSPECTION_STEP2_FIRST_INSPECTION') }}
      </p>
      <div class="form-wrap-input">
        <p>{{ getInspectionTxt(freeField.first_inspection, freeField.first_inspection_other) }}</p>
      </div>
    </div>
    <div class="form-wrap">
      <p class="form-wrap-tit">
        {{ $t('INSPECTION_STEP2_INSPEC_EXPIRE') }}
        <br class="only_pc">
        {{ $t('INSPECTION_STEP2_INSPEC_EXPIRE_2') }}
      </p>
      <div class="form-wrap-input">
        <p>{{ getInspectionTxt(freeField.inspec_expire, freeField.inspec_expire_other) }}</p>
      </div>
    </div>
    <div class="form-wrap">
      <p class="form-wrap-tit">
        {{ $t('INSPECTION_STEP2_CUBIC_CAPACITY_1') }}
      </p>
      <div class="form-wrap-input">
        <p v-if="freeField.cubic_capacity_other">{{ freeField.cubic_capacity_other | getCubicText }}</p>
        <p v-else>{{ $t('ITEM_FREE_FIELD_FUMEI') }}</p>
      </div>
    </div>
    <div class="form-wrap">
      <p class="form-wrap-tit">
        {{ $t('INSPECTION_STEP2_FUEL_TYPE') }}
      </p>
      <div class="form-wrap-input">
          {{ constants.SELECT_FUEL_TYPE.filter(x => String(x.value) === String(freeField.fuel_type)).map(x => x.label).join() }}
      </div>
    </div>
    <div class="form-wrap">
      <p class="form-wrap-tit">
        {{ $t('INSPECTION_STEP2_MILEAGE') }}
      </p>
      <div class="form-wrap-input">
        <p v-if="freeField.mileage_other">{{ freeField.mileage_other }}</p>
        <p v-else>{{ $t('ITEM_FREE_FIELD_FUMEI') }}</p>
      </div>
    </div>
    <div class="form-wrap">
      <p class="form-wrap-tit">
        {{ $t('INSPECTION_STEP2_COLOR') }}
      </p>
      <div class="form-wrap-input">
        <p v-if="freeField.color_other">{{ freeField.color_other }}</p>
        <p v-else>{{ $t('ITEM_FREE_FIELD_FUMEI') }}</p>
      </div>
    </div>
    <div class="form-wrap">
      <p class="form-wrap-tit">
        {{ $t('INSPECTION_STEP2_METER') }}
      </p>
      <div class="form-wrap-input">
        <div class="form-wrap-radioFlex">
          <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_METER_SPEED') }}</span>
          <div class="form-wrap-radio">
            <p v-if="freeField.meter_speed_other">{{ freeField.meter_speed_other }}</p>
            <p v-else>{{ (constants.SELECT_YES_NO || []).filter(x => x.value === String(freeField.meter_speed)).map(x => x.label).join() }}</p>
          </div>
        </div>
        <div class="form-wrap-radioFlex">
          <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_METER_TACO') }}</span>
          <div class="form-wrap-radio">
            <p v-if="freeField.meter_taco_other">{{ freeField.meter_taco_other }}</p>
            <p v-else>{{ (constants.SELECT_YES_NO_TACO || []).filter(x => x.value === String(freeField.meter_taco)).map(x => x.label).join() }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="form-wrap">
      <p class="form-wrap-tit">
        {{ $t('INSPECTION_STEP2_HORN') }}
      </p>
      <div class="form-wrap-input">
        <p v-if="freeField.horn_other">{{ freeField.horn_other }}</p>
        <p v-else>{{ (constants.SELECT_YES_NO || []).filter(x => x.value === String(freeField.horn)).map(x => x.label).join() }}</p>
      </div>
    </div>
    <div class="form-wrap">
      <p class="form-wrap-tit">
        {{ $t('INSPECTION_STEP2_HEAD_LIGHT') }}
      </p>
      <div class="form-wrap-input">
        <div class="form-wrap-radioFlex">
          <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_HEAD_LIGHT_HI') }}</span>
          <div class="form-wrap-radio">
            <p v-if="freeField.headlight_hi_other">{{ freeField.headlight_hi_other }}</p>
            <p v-else>{{ (constants.SELECT_YES_NO || []).filter(x => x.value === String(freeField.headlight_hi)).map(x => x.label).join() }}</p>
          </div>
        </div>
        <div class="form-wrap-radioFlex">
          <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_HEAD_LIGHT_LO') }}</span>
          <div class="form-wrap-radio">
            <p v-if="freeField.headlight_lo_other">{{ freeField.headlight_lo_other }}</p>
            <p v-else>{{ (constants.SELECT_YES_NO || []).filter(x => x.value === String(freeField.headlight_lo)).map(x => x.label).join() }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="form-wrap">
      <p class="form-wrap-tit">
        {{ $t('INSPECTION_STEP2_WINKER') }}
      </p>
      <div class="form-wrap-input">
        <div class="form-wrap-radioFlex">
          <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_WINKER_FL') }}</span>
          <div class="form-wrap-radio">
            <p v-if="freeField.winker_fl_other">{{ freeField.winker_fl_other }}</p>
            <p v-else>{{ (constants.SELECT_YES_NO || []).filter(x => x.value === String(freeField.winker_fl)).map(x => x.label).join() }}</p>
          </div>
        </div>
        <div class="form-wrap-radioFlex">
          <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_WINKER_FR') }}</span>
          <div class="form-wrap-radio">
            <p v-if="freeField.winker_fr_other">{{ freeField.winker_fr_other }}</p>
            <p v-else>{{ (constants.SELECT_YES_NO || []).filter(x => x.value === String(freeField.winker_fr)).map(x => x.label).join() }}</p>
          </div>
        </div>
        <div class="form-wrap-radioFlex">
          <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_WINKER_RL') }}</span>
          <div class="form-wrap-radio">
            <p v-if="freeField.winker_rl_other">{{ freeField.winker_rl_other }}</p>
            <p v-else>{{ (constants.SELECT_YES_NO || []).filter(x => x.value === String(freeField.winker_rl)).map(x => x.label).join() }}</p>
          </div>
        </div>
        <div class="form-wrap-radioFlex">
          <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_WINKER_RR') }}</span>
          <div class="form-wrap-radio">
            <p v-if="freeField.winker_rr_other">{{ freeField.winker_rr_other }}</p>
            <p v-else>{{ (constants.SELECT_YES_NO || []).filter(x => x.value === String(freeField.winker_rr)).map(x => x.label).join() }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="form-wrap">
      <p class="form-wrap-tit">
        {{ $t('INSPECTION_STEP2_TAIL_LIGHT') }}
      </p>
      <div class="form-wrap-input">
        <div class="form-wrap-radioFlex">
          <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_TAIL_LIGHT_SMALL') }}</span>
          <div class="form-wrap-radio">
            <p v-if="freeField.tail_light_small_other">{{ freeField.tail_light_small_other }}</p>
            <p v-else>{{ (constants.SELECT_YES_NO || []).filter(x => x.value === String(freeField.tail_light_small)).map(x => x.label).join() }}</p>
          </div>
        </div>
        <div class="form-wrap-radioFlex">
          <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_TAIL_LIGHT_BREAK') }}</span>
          <div class="form-wrap-radio">
            <p v-if="freeField.tail_light_break_other">{{ freeField.tail_light_break_other }}</p>
            <p v-else>{{ (constants.SELECT_YES_NO || []).filter(x => x.value === String(freeField.tail_light_break)).map(x => x.label).join() }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="form-wrap">
      <p class="form-wrap-tit">
        {{ $t('INSPECTION_STEP2_BATTERY_HEALTH') }}
      </p>
      <div class="form-wrap-input">
        <p v-if="freeField.battery_health_other">{{ freeField.battery_health_other }}</p>
        <p v-else>{{ (constants.SELECT_BATTERY_HEALTH || []).filter(x => x.value === String(freeField.battery_health)).map(x => x.label).join() }}</p>
      </div>
    </div>
    <div class="form-wrap">
      <p class="form-wrap-tit">
        {{ $t('INSPECTION_STEP2_RADIATOR_LEAK') }}
      </p>
      <div class="form-wrap-input">
        <p v-if="freeField.radiator_leak_other">{{ freeField.radiator_leak_other }}</p>
        <p v-else>{{ (constants.SELECT_RADIATOR_LEAK || []).filter(x => x.value === String(freeField.radiator_leak)).map(x => x.label).join() }}</p>
      </div>
    </div>
    <div class="form-wrap">
      <p class="form-wrap-tit">
        {{ $t('INSPECTION_STEP2_ENGINE_START_STATUS') }}
        <br class="only_pc">
        {{ $t('INSPECTION_STEP2_ENGINE_START_STATUS_2') }}
      </p>
      <div class="form-wrap-input">
        <div class="form-wrap-radioFlex">
          <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_WHITE_SMOKE') }}</span>
          <div class="form-wrap-radio">
            <p v-if="freeField.engine_white_smoke_other">{{ freeField.engine_white_smoke_other }}</p>
            <p v-else>{{ (constants.SELECT_ARI_NASI || []).filter(x => x.value === String(freeField.engine_white_smoke)).map(x => x.label).join() }}</p>
          </div>
        </div>
        <div class="form-wrap-radioFlex">
          <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_ENGINE_NOISE') }}</span>
          <div class="form-wrap-radio">
            <p v-if="freeField.engine_noise_other">{{ freeField.engine_noise_other }}</p>
            <p v-else>{{ (constants.SELECT_ARI_NASI || []).filter(x => x.value === String(freeField.engine_noise)).map(x => x.label).join() }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="form-wrap">
      <p class="form-wrap-tit">
        {{ $t('INSPECTION_STEP2_FRONT_FORK_STATUS') }}
      </p>
      <div class="form-wrap-input">
        <div class="form-wrap-radioFlex">
          <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_FFORK_STRAIN') }}</span>
          <div class="form-wrap-radio">
            <p v-if="freeField.front_fork_strain_other">{{ freeField.front_fork_strain_other }}</p>
            <p v-else>{{ (constants.SELECT_ARI_NASI || []).filter(x => x.value === String(freeField.front_fork_strain)).map(x => x.label).join() }}</p>
          </div>
        </div>
        <div class="form-wrap-radioFlex">
          <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_RUSTY') }}</span>
          <div class="form-wrap-radio">
            <p v-if="freeField.front_fork_rusty_other">{{ freeField.front_fork_rusty_other }}</p>
            <p v-else>{{ (constants.SELECT_ARI_NASI || []).filter(x => x.value === String(freeField.front_fork_rusty)).map(x => x.label).join() }}</p>
          </div>
        </div>
        <div class="form-wrap-radioFlex">
          <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_OIL_LEAK') }}</span>
          <div class="form-wrap-radio">
            <p v-if="freeField.front_fork_oil_leak_other">{{ freeField.front_fork_oil_leak_other }}</p>
            <p v-else>{{ (constants.SELECT_ARI_NASI || []).filter(x => x.value === String(freeField.front_fork_oil_leak)).map(x => x.label).join() }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="textarea-wrap">
      <p class="form-wrap-tit">
        {{ $t('INSPECTION_STEP2_HANDLE') }}
      </p>
      <div class="form-wrap-input">
        <p style="white-space: pre-line" v-if="freeField.handle_other">{{ freeField.handle_other }}</p>
        <p v-else>{{ $t('ITEM_FREE_FIELD_FUMEI') }}</p>
      </div>
    </div>
    <div class="textarea-wrap">
      <p class="form-wrap-tit">
        {{ $t('INSPECTION_STEP2_FRONT_BREAK') }}
        <br>{{ $t('INSPECTION_STEP2_FRONT_BREAK_2') }}
        <br>{{ $t('INSPECTION_STEP2_FRONT_BREAK_3') }}
      </p>
      <div class="form-wrap-input">
        <p style="white-space: pre-line" v-if="freeField.front_break_other">{{ freeField.front_break_other }}</p>
        <p v-else>{{ $t('ITEM_FREE_FIELD_FUMEI') }}</p>
      </div>
    </div>
    <div class="textarea-wrap">
      <p class="form-wrap-tit">
        {{ $t('INSPECTION_STEP2_REAR_BREAK') }}
        <br>
        {{ $t('INSPECTION_STEP2_REAR_BREAK_2') }}
        <br>
        {{ $t('INSPECTION_STEP2_REAR_BREAK_3') }}
      </p>
      <div class="form-wrap-input">
        <p style="white-space: pre-line" v-if="freeField.rear_break_other">{{ freeField.rear_break_other }}</p>
        <p v-else>{{ $t('ITEM_FREE_FIELD_FUMEI') }}</p>
      </div>
    </div>
    <div class="textarea-wrap">
      <p class="form-wrap-tit">
        {{ $t('INSPECTION_STEP2_ENGINE_CONDITION') }}
      </p>
      <div class="form-wrap-input">
        <p style="white-space: pre-line">{{ freeField.engine_condition }}</p>
      </div>
    </div>
    <div class="textarea-wrap">
      <p class="form-wrap-tit">
        {{ $t('INSPECTION_STEP2_TIRE_CONDITION') }}
      </p>
      <div class="form-wrap-input">
        <p style="white-space: pre-line">{{ freeField.tire_condition }}</p>
      </div>
    </div>
    <div class="textarea-wrap">
      <p class="form-wrap-tit">
        {{ $t('INSPECTION_STEP2_MODIFICATION') }}
      </p>
      <div class="form-wrap-input">
        <p style="white-space: pre-line">{{ freeField.modification }}</p>
      </div>
    </div>
    <div class="textarea-wrap">
      <p class="form-wrap-tit">
        {{ $t('INSPECTION_STEP2_SCRATCH') }}
      </p>
      <div class="form-wrap-input">
        <p style="white-space: pre-line">{{ freeField.scratch }}</p>
      </div>
    </div>
    <div class="textarea-wrap">
      <p class="form-wrap-tit">
        {{ $t('INSPECTION_STEP2_OTHER_MEMO') }}
      </p>
      <div class="form-wrap-input">
        <p style="white-space: pre-line">{{ freeField.other_memo }}</p>
      </div>
    </div>
  </div>
</form>
</template>

<script>
import commonFilters from '@/common/commonFilters';
import DetailDocumentFiles from './DetailDocumentFiles';
import DetailItemImageView from './DetailItemImageView';
export default {
  components : {
    DetailItemImageView,
    DetailDocumentFiles
  },
  mixins : [commonFilters],
  props  : {
    freeField : {
      type    : Object,
      default : () => {}
    },
    itemImages : {
      type    : Array,
      default : () => []
    },
    optionalImages : {
      type    : Array,
      default : () => []
    },
    constants : {
      type    : Object,
      default : () => {}
    },
    getInspectionTxt : {
      type    : Function,
      default : Object
    },
  },
}
</script>
<style scoped lang="scss">
  .entry-result {
    width: 100%;
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
