<script>
import {defineComponent} from '@vue/composition-api';
import FileComp from './FileComp.vue';
export default defineComponent({
  components : {
    FileComp
  },
  props : {
    documentFiles : {
      type    : Array,
      default : () => []
    }
  },
  data() {
    return {
      initDocumentFiles : [
        {id : 1, label : '車検証', key : null, file : null},
        {id : 2, label : '予備検査証', key : null, file : null},
        {id : 3, label : '廃車・返納・一時抹消証明書', key : null, file : null},
        {id : 4, label : '通関証明証', key : null, file : null},
      ],
    }
  },
  computed : {
  },
  methods : {
  },
})
</script>
<template>
<div class="document-file">
  <div class="form-wrap align-start">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_DOCUMENT_FILES_LABEL') }}
    </p>
    <div class="form-wrap-input">
      <div v-if="documentFiles && documentFiles.length > 0">
        <div v-for="(doc, index) in documentFiles" :key="index" class="form-wrap align-center">
          <p class="form-wrap-tit document">{{ (initDocumentFiles.find(x => String(x.id) === String(doc.id)) || {}).label }}</p>
          <div  class="ipt-file ipt-file-box">
            <p class="ipt-file-txt">{{ doc.key ? $t('INSPECTION_DOCUMENT_FILE_ARI') : $t('INSPECTION_DOCUMENT_FILE_NASI') }}</p>
            <!-- <file-comp
              :title="(initDocumentFiles.find(x => String(x.id) === String(doc.id)) || {}).label"
              nullText="ファイルを選択しません"
              :fileInfo="{
                key: doc.key
              }"
            /> -->
          </div>
        </div>
      </div>
      <div v-else>なし</div>
    </div>
  </div>
</div>
</template>
<style lang="scss" scoped>
  .form-wrap-tit.document {
    text-align: right !important;
  }
  .document-file {
    margin-top: 30px;
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
